<template>
    <div class="bg-slate-200">
        <ClientOnly>

            
            <div class="bg-primary-500 h-1">            
            </div>
        </ClientOnly>
        
        <slot></slot>
        <Footer></Footer>
    </div>
</template>